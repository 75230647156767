footer {
    width: 100%;
    bottom: 0;
    color: white;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 1.5vw;
    font-size: 20px;
  }
  
  .logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 2vw;
  }
  
  .logos .link {
    margin-left: 2vw;
    color: white;
  }
  
  @media screen and (max-width: 1000px) {
    .footer-content {
      display: flex;
      flex-direction: column; 
      align-items: center;
      text-align: center;
    }
  
    .logos {
      margin: 1rem 0;
      flex-direction: row;
      justify-content: center;
    }
  
    .logos .link {
      margin-left: 4vw;
    }
  }
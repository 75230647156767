.About-page{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding-top: 4vh;
}

.about-sub{
    display: flex;
    justify-content: center;
    align-items: center;
    width:90%;
    padding-top: 10vh;
    padding-bottom: 4vh;
}

.butn{
    border-radius:10px;
    border-width: 1px;
    border-color: lightblue;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.butn:hover{
    background: lightblue;
    color: black;
    border-color: rgba(255,255,255,0.2);
}


.image{
    margin-left: 3vw;
    border-radius: 5px;
}

.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; 
}



@media (max-width: 800px) {
    .about-sub{
        flex-direction: column;
        justify-content: center;
        
    }

    .iframe{
        order: -2
    }

    .header2 {
        text-align: center;
    }

    .image{
        width: 80%;
        margin-bottom: 3vh;
        margin-left: 0;
        order: -1;
    }   

    .bio{
        text-align: center;
    }
  }
.skillsbg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.stock-ticker {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
  overflow: hidden;
  user-select: none;
  --gap: 20px;
  display: flex;
  gap: var(--gap);
  z-index: -1;
  background-color: #f5f5f5;
}

.stock-ticker ul {
  list-style: none;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  animation: scroll 20s linear infinite;
}

.stock-ticker:hover ul {
  animation-play-state: paused;
}

@keyframes scroll {
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 800px) {
  .stock-ticker {
    width: 100%;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Mobile/Desktop Navbar */
.header {
  height: 70px;
  width: 100%;
  background-color: rgb(19, 8, 25);
  z-index: 100;
  position: fixed;
  color: lightblue;
  font-size: 20px;
}

.active:not(.title) {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: 3px;
}

.drawer-list {
  width: 250px;
}

/* ********************* */



body::-webkit-scrollbar{
  display: none;
}

body {
  background-image: url("./images/bg.png"); 
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5vw;
  width: 100%;
}


.mobile-only {
  display: none;
  z-index: 100;
}


@media (max-width: 900px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

    .desktop-only {
      display: none;
    }
  
    .mobile-only {
      display: block;
    }
  
  
   
  
}












@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
